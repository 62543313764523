/* eslint-disable indent */
import { AxiosRequestConfig } from 'axios';
import {
  Application,
  RequiredDocuments,
  SunstoneDocument,
} from '../../../../../types/api';
import {
  USER_APPLICATIONS_URL,
  INSTALLER_DASHBOARD_APPLICATIONS_URL,
  INSTALLER_DASHBOARD_APPLICATIONS_SUMMARY_URL,
} from '../../constants';
import {
  BadRequest,
  GeneralError,
  ServerError,
  UserNotFound,
} from '../../utils/errors';
import noticeError from '../../utils/errors/noticeError';
import { api } from './API';

class ApplicationAPI {
  async getUserApplications() {
    try {
      const response = await api().get<Application[]>(USER_APPLICATIONS_URL);
      return response.data;
    } catch (error) {
      noticeError(error);
      const { status } = error.response;
      throw getErrorByStatusCode(status);
    }
  }

  async getInstallerDashboardApplications(
    accountId: string,
    accountType: string,
    role: string,
    contactId: string
  ) {
    const response = await api().get<any>(
      INSTALLER_DASHBOARD_APPLICATIONS_URL,
      {
        params: { accountId, accountType, role, contactId },
      }
    );
    return response.data;
  }

  async getInstallerDashboardApplicationsSummary(
    accountId: string,
    accountType: string,
    role: string,
    contactId: string
  ) {
    const response = await api().get<any>(
      INSTALLER_DASHBOARD_APPLICATIONS_SUMMARY_URL,
      {
        params: { accountId, accountType, role, contactId },
      }
    );
    return response.data;
  }

  async getApplication(loanId) {
    try {
      const response = await api().get<Application>(
        `${USER_APPLICATIONS_URL}${loanId}/`
      );
      return response.data;
    } catch (error) {
      noticeError(error);
      const { status } = error.response;
      throw getErrorByStatusCode(status);
    }
  }

  async updateApplication(
    loanId: string,
    data: Partial<Application | RequiredDocuments>
  ) {
    try {
      const response = await api().put(
        `${USER_APPLICATIONS_URL}${loanId}/`,
        data
      );
      return response.data;
    } catch (error) {
      noticeError(error);
      const { status } = error.response;
      throw getErrorByStatusCode(status);
    }
  }
  /**
   * Get the list of principals for a loan
   * @param {string} loanId
   * @returns {Promise<object[]>}
   */
  async getApplicationPrincipals(loanId) {
    try {
      const response = await api().get(
        `${USER_APPLICATIONS_URL}${loanId}/principals`
      );
      return response.data;
    } catch (error) {
      noticeError(error);
      const { status } = error.response;
      throw getErrorByStatusCode(status);
    }
  }

  // @deprecated
  async OLD_uploadApplicationDocument(
    applicationId: string,
    data: FormData,
    params: Record<string, string> = {}
  ) {
    const config: AxiosRequestConfig = {
      timeout: 10 * 60 * 1000,
      params,
    };
    try {
      const response = await api().post<{ data: SunstoneDocument }>(
        `${USER_APPLICATIONS_URL}${applicationId}/OLD-documents/`,
        data,
        config
      );
      return response.data.data;
    } catch (error) {
      noticeError(error);
      const { status } = error.response;
      throw getErrorByStatusCode(status);
    }
  }

  async uploadApplicationDocument(
    applicationId: string,
    data: FormData,
    params: Record<string, string> = {}
  ) {
    const config: AxiosRequestConfig = {
      timeout: 10 * 60 * 1000,
      params,
    };
    try {
      const response = await api().post<{ data: SunstoneDocument }>(
        `${USER_APPLICATIONS_URL}${applicationId}/documents/`,
        data,
        config
      );
      return response.data.data;
    } catch (error) {
      noticeError(error);
      const { status } = error.response;
      throw getErrorByStatusCode(status);
    }
  }

  async getApplicationDocuments(loanId) {
    try {
      const response = await api().get<{ data: RequiredDocuments }>(
        `${USER_APPLICATIONS_URL}${loanId}/documents/`
      );
      return response.data.data;
    } catch (error) {
      noticeError(error);
      const { status } = error.response;
      throw getErrorByStatusCode(status);
    }
  }

  async deleteApplicationDocument(loanId: string, documentId: string) {
    try {
      await api().delete<Record<string, never>>(
        `${USER_APPLICATIONS_URL}${loanId}/documents/${documentId}`
      );
      return;
    } catch (error) {
      noticeError(error);
      const { status } = error.response;
      throw getErrorByStatusCode(status);
    }
  }

  async listPrincipals(loanId) {
    try {
      const response = await api().get(
        `${USER_APPLICATIONS_URL}${loanId}/principals`
      );
      return response.data;
    } catch (error) {
      noticeError(error);
      const { status } = error.response;
      throw getErrorByStatusCode(status);
    }
  }
  async createPrincipal(loanId, data) {
    try {
      const response = await api().post(
        `${USER_APPLICATIONS_URL}${loanId}/principals`,
        data
      );
      return response.data;
    } catch (error) {
      noticeError(error);
      const { status } = error.response;
      throw getErrorByStatusCode(status);
    }
  }
  async updatePrincipal(loanId, principalId, updates) {
    try {
      const response = await api().put(
        `${USER_APPLICATIONS_URL}${loanId}/principals/${principalId}`,
        updates
      );
      return response.data;
    } catch (error) {
      noticeError(error);
      const { status } = error.response;
      throw getErrorByStatusCode(status);
    }
  }

  async deletePrincipal(loanId, principalId) {
    try {
      const response = await api().delete(
        `${USER_APPLICATIONS_URL}${loanId}/principals/${principalId}`
      );
      return response.data;
    } catch (error) {
      noticeError(error);
      const { status } = error.response;
      throw getErrorByStatusCode(status);
    }
  }

  async getApplicationTasks(loanId) {
    try {
      const response = await api().get(
        `${USER_APPLICATIONS_URL}${loanId}/tasks`
      );
      return response.data;
    } catch (error) {
      noticeError(error);
      const { status } = error.response;
      throw getErrorByStatusCode(status);
    }
  }
}

export const getErrorByStatusCode = (statusCode) => {
  switch (statusCode) {
    case 200:
    case 201:
    case 204:
      break;

    case 400:
      throw new BadRequest();

    case 401:
      throw new UserNotFound();

    case 503:
      throw new ServerError();

    default:
      throw new GeneralError();
  }
};

export const applicationAPI = new ApplicationAPI();
