import { ProductFamily } from '../../../types/api';
import StandardIcon from './components/pricing-calculator/StandardIcon';
import CalendarIcon from './components/pricing-calculator/CalendarIcon';
import { isCurrentDateBetween } from './components/loan_application/utils/helpers';

// Landing page link slugs
export const TERMS_OF_USE_SLUG = 'terms-of-use';
export const PRIVACY_POLICY_SLUG = 'privacy-policy';
export const CONTACT_SLUG = 'contact';
export const ACCESSIBILITY_SLUG = 'accessibility-statement';

// API endpoint paths
export const CREATE_SCHEMA_URL = 'users/sign-up/';
export const LOGOUT_URL = 'auth/logout/';
export const SIGNIN_URL = 'auth/sign-in/';
export const REFRESH_TOKEN_URL = 'auth/token/refresh/';
export const SIGNUP_URL = 'auth/sign-up/';
export const VERIFY_URL = 'auth/verify/';
export const UPDATE_PASSWORD_URL = 'auth/update-password/';
export const RESET_PASSWORD_URL = 'auth/reset-password/';
export const CHECK_PASSWORD_RESET_URL = 'auth/check-password-reset-code/';
export const USER_URL = 'users/';
export const USER_ACCOUNT_URL = 'users/account';
export const INSTALLER_APPLICATIONS_URL = 'installers/applications/';
export const INSTALLER_BORROWERS_URL = 'installers/borrowers/';
export const INSTALLER_SUMMARY_URL = 'installers/summary/';
export const INSTALLER_PROJECT_SUMMARY_URL = 'installers/summary/projects';
export const USER_APPLICATIONS_URL = 'user/applications/';
export const BORROWER_DOCUMENTS_URL = 'user/documents/';
export const PLAID_CREATE_LINK_TOKEN_URL = 'plaid/create_link_token/';
export const PLAID_GENERATE_ASSET_REPORT_URL = 'plaid/generate_asset_report/';
export const PLAID_GET_ASSET_REPORT_URL = 'plaid/get_asset_report/';
export const PLAID_DELETE_ASSET_REPORT = 'plaid/delete_asset_report/';
export const FUNDED_PROJECTS_URL = 'projects';
export const PORTAL_RESOURCES_URL = 'resources/';
export const PORTAL_RATES_URL = 'rates/';

// App internal routes
export const INSTALLER_ACCOUNT = 'dashboard/installer/account/';
export const INSTALLER_DASHBOARD_PATH = 'dashboard/borrowers';
export const PROJECT_DETAILS_PATH = 'dashboard/projects/';
export const USER_SETTINGS_PATH = 'settings';
export const DEBUG_SETTINGS_PATH = 'debug';
export const NEW_APPLICATION_PATH = 'new-applicant';
export const INSTALLER_DASHBOARD_FUNDED_PROJECTS_URL =
  'installer/dashboard/projects';
export const INSTALLER_DASHBOARD_PROJECTS_SUMMARY_URL =
  'installer/dashboard/projects/summary';
export const INSTALLER_DASHBOARD_APPLICATIONS_URL =
  'installer/dashboard/applications';
export const INSTALLER_DASHBOARD_APPLICATIONS_SUMMARY_URL =
  'installer/dashboard/applications/summary';

// Business logic constants
export const APP_EXTERNAL_STATUSES = {
  CREATED: 'Application Created',
  IN_PROGRESS: 'Application In Progress',
  SUBMITTED: 'Application Submitted',
  LOAN_PACKAGE: 'Loan Package',
  COMPLETE: 'Complete',
  EXPIRED: 'Expired',
  LOAN_AGREEMENT_EXPIRED: 'Loan Agreement Expired',
  CLOSED: 'Closed',
} as const;

export const FP_EXTERNAL_STATUSES = {
  IN_PROGRESS: 'In Progress',
  PENDING_CHANGE_ORDER: 'Pending Change Order',
  FULLY_FUNDED: 'Fully Funded',
  CANCELED: 'Canceled',
  CLOSED: 'Closed',
} as const;

export enum EXTERNAL_ACTIONS {
  BEGIN_APPLICATION = 'Begin Application',
  CANCELED = 'Canceled',
  CLOSED = 'Closed',
  EXPIRED = 'Expired',
  FINISH_APPLICATION = 'Finish Application',
  LOAN_APPROVED = 'Loan Approved',
  LOAN_DECLINED = 'Loan Declined',
  LOAN_PROCESSING = 'Loan Processing',
  PENDING_CHANGE_REQUEST = 'Pending Change Request',
  PROJECT_DELAYED = 'Project Delayed',
  PROVIDE_ADDITIONAL_INFO = 'Provide Additional Information',
  SIGN_LOAN_PACKAGE = 'Sign Loan Package',
  UNDER_REVIEW = 'Under Review',
  UPLOAD_DOCUMENTS = 'Upload Documents',
  MISSING_DOCUMENTS = 'Missing Documents',
}

export const EXTERNAL_ACTION_ORDER = new Map<any, number>([
  [EXTERNAL_ACTIONS.BEGIN_APPLICATION, 0],
  [EXTERNAL_ACTIONS.FINISH_APPLICATION, 1],
  [EXTERNAL_ACTIONS.UPLOAD_DOCUMENTS, 2],
  [EXTERNAL_ACTIONS.UNDER_REVIEW, 3],
  [EXTERNAL_ACTIONS.LOAN_APPROVED, 4],
  [EXTERNAL_ACTIONS.LOAN_DECLINED, 5],
  [EXTERNAL_ACTIONS.LOAN_PROCESSING, 6],
  [EXTERNAL_ACTIONS.PENDING_CHANGE_REQUEST, 7],
  [EXTERNAL_ACTIONS.PROJECT_DELAYED, 8],
  [EXTERNAL_ACTIONS.PROVIDE_ADDITIONAL_INFO, 9],
  [EXTERNAL_ACTIONS.SIGN_LOAN_PACKAGE, 10],
  [EXTERNAL_ACTIONS.CANCELED, 11],
  [EXTERNAL_ACTIONS.CLOSED, 12],
  [EXTERNAL_ACTIONS.EXPIRED, 13],
]);

export const APP_INTERNAL_STATUSES = {
  APP_CREATED: 'Application Created',
  APP_PENDING: 'Application Pending',
  DOCS_PENDING: 'Documents Pending',
  PENDING_BORROWER: 'Pending Borrower',
  PENDING_INSTALLER: 'Pending Installer',
  APP_COMPLETE: 'Application Complete',
  UW_IN_PROGRESS: 'UW In-Progress',
  UW_COMPLETE: 'UW Complete',
  UW_PENDING_COUNTEROFFER: 'UW Pending Counteroffer',
  COMPLIANCE_PENDING: 'Compliance Pending',
  COMPLIANCE_FAIL: 'Compliance Fail',
  COMPLIANCE_PASS: 'Compliance Pass',
  AGREEMENTS_PENDING: 'Agreements Pending',
  AGREEMENTS_EXECUTED: 'Agreements Executed',
  AGREEMENTS_COMPLETE: 'Agreement Complete',
  READY_TO_FUND: 'Ready to Fund',
  FUNDED: 'Funded',
  DECLINED: 'Declined',
  CLOSED: 'Closed',
  CANCELED_INSTALLER: 'Canceled: Installer',
  CANCELED_BORROWER: 'Canceled: Borrower',
  CANCELED_SUNSTONE: 'Canceled: Sunstone',
  CANCELED_NO_CONTACT: 'Canceled: Do Not Contact',
  CANCELED_EXPIRED: 'Canceled: Expired',
  DELAYED: 'Delayed',
} as const;

export const SYSTEM_POWER_USAGE_VALUES = {
  OFFSET_BORROWERS_UTILITY_BILL: "Offset Borrower's utility bill",
  SOLD_TO_ANOTHER_ENTITY: 'Sold to another entity',
};

export const PROJECT_MILESTONE = {
  NTP: 'Notice to Proceed',
  EO: 'Equipment Order',
  RC: 'Racking Completion',
  MC: 'Mechanical Completion',
  SC: 'Substantial Completion',
  FC: 'Final Completion',
  FullyFunded: 'Fully Funded',
} as const;

export const PROJECT_MILESTONE_ORDER = new Map<any, number>([
  [PROJECT_MILESTONE.NTP, 0],
  [PROJECT_MILESTONE.EO, 1],
  [PROJECT_MILESTONE.RC, 2],
  [PROJECT_MILESTONE.MC, 3],
  [PROJECT_MILESTONE.SC, 4],
  [PROJECT_MILESTONE.FC, 5],
  [PROJECT_MILESTONE.FullyFunded, 6],
]);

export enum MILESTONE_TYPES {
  NOTICE_TO_PROCEED = 'NTP',
  EQUIPMENT_ORDER = 'EO',
  RACKING_COMPLETION = 'RC',
  MECHANICAL_COMPLETION = 'MC',
  SUBSTANTIAL_COMPLETION = 'SC',
  FINAL_COMPLETION = 'FC',
  FULLY_FUNDED = 'FullyFunded',
}

// 'Notice to Proceed' -> 'NTP', etc.
export const MILESTONE_LONG_TO_SHORT = {
  [PROJECT_MILESTONE.NTP]: MILESTONE_TYPES.NOTICE_TO_PROCEED,
  [PROJECT_MILESTONE.EO]: MILESTONE_TYPES.EQUIPMENT_ORDER,
  [PROJECT_MILESTONE.RC]: MILESTONE_TYPES.RACKING_COMPLETION,
  [PROJECT_MILESTONE.MC]: MILESTONE_TYPES.MECHANICAL_COMPLETION,
  [PROJECT_MILESTONE.SC]: MILESTONE_TYPES.SUBSTANTIAL_COMPLETION,
  [PROJECT_MILESTONE.FC]: MILESTONE_TYPES.FINAL_COMPLETION,
  [PROJECT_MILESTONE.FullyFunded]: MILESTONE_TYPES.FULLY_FUNDED,
} as const;

export const MILESTONE_SHORT_TO_LONG = {
  [MILESTONE_TYPES.NOTICE_TO_PROCEED]: PROJECT_MILESTONE.NTP,
  [MILESTONE_TYPES.EQUIPMENT_ORDER]: PROJECT_MILESTONE.EO,
  [MILESTONE_TYPES.RACKING_COMPLETION]: PROJECT_MILESTONE.RC,
  [MILESTONE_TYPES.MECHANICAL_COMPLETION]: PROJECT_MILESTONE.MC,
  [MILESTONE_TYPES.SUBSTANTIAL_COMPLETION]: PROJECT_MILESTONE.SC,
  [MILESTONE_TYPES.FINAL_COMPLETION]: PROJECT_MILESTONE.FC,
  [MILESTONE_TYPES.FULLY_FUNDED]: PROJECT_MILESTONE.FullyFunded,
} as const;

// LTT Task enums
export const APP_TASK_STATUS = {
  OPEN: 'OPEN',
  COMPLETED: 'COMPLETED',
} as const;

export const APP_TASK_TYPES = {
  TASK: 'Task',
  EMAIL: 'Email',
  LIST_EMAIL: 'List Email',
  CADENCE: 'Cadence',
  CALL: 'Call',
} as const;

export const USER_RECORD_TYPES = {
  BORROWER: 'borrower',
  INSTALLER: 'installer',
  PARTNER: 'partner',
} as const;

export const ACCOUNT_TYPES = {
  BORROWER: 'Borrower',
  CONSULTANT: 'Consultant',
  INSTALLER: 'Installer',
} as const;

export const INSTALLER_TYPES = {
  SALES: 'Sales',
  ADMIN: 'Admin',
  ACCOUNTING: 'Accounting',
} as const;

export const RELATED_PARTY_TYPES = {
  INDIVIDUAL: 'Individual',
  ENTITY: 'Entity',
} as const;

export const NUMBER_FORMATS = {
  ZIP: '#####',
  YEAR: '####',
  SSN: '###-##-####',
  PHONE: '(###) ###-####',
  EIN: '#########',
} as const;

export const PLACEHOLDERS = {
  PHONE: '(000)000-0000',
  OPTIONAL: '(Optional)',
} as const;

export const MAX_NUMBER_INPUT_VALUE = 99999999999;

export const YES_NO = [
  { label: 'Yes', value: 'Yes' },
  { label: 'No', value: 'No' },
];

export const YES_NO_UPPERCASE = [
  { label: 'Yes', value: 'YES' },
  { label: 'No', value: 'NO' },
];

export const RENT_OWN = [
  { label: 'Rent', value: 'Rent' },
  { label: 'Own', value: 'Own' },
];

export const OCCUPATIONS = [
  { label: 'Accountant', value: 'Accountant' },
  { label: 'Administrative Support', value: 'Administrative Support' },
  { label: 'Architect', value: 'Architect' },
  { label: 'Business Owner', value: 'Business Owner' },
  { label: 'Chef', value: 'Chef' },
  { label: 'Construction Worker', value: 'Construction Worker' },
  { label: 'Counselor', value: 'Counselor' },
  { label: 'Dentist', value: 'Dentist' },
  { label: 'Doctor', value: 'Doctor' },
  { label: 'Driver', value: 'Driver' },
  { label: 'Engineer', value: 'Engineer' },
  { label: 'Farmer', value: 'Farmer' },
  { label: 'Financial Professional', value: 'Financial Professional' },
  { label: 'Firefighter', value: 'Firefighter' },
  { label: 'Food Server', value: 'Food Server' },
  { label: 'Janitorial Worker', value: 'Janitorial Worker' },
  { label: 'Law Enforcement', value: 'Law Enforcement' },
  { label: 'Lawyer', value: 'Lawyer' },
  { label: 'Nurse', value: 'Nurse' },
  {
    label: 'Personal Care and Service Worker',
    value: 'Personal Care and Service Worker',
  },
  { label: 'Pharmacist', value: 'Pharmacist' },
  { label: 'Real Estate Agent', value: 'Real Estate Agent' },
  { label: 'Religious Worker', value: 'Religious Worker' },
  { label: 'Sales Representative', value: 'Sales Representative' },
  { label: 'Software Developer', value: 'Software Developer' },
  { label: 'Teacher', value: 'Teacher' },
  { label: 'Technician', value: 'Technician' },
  { label: 'Therapist', value: 'Therapist' },
  { label: 'Veterinarian', value: 'Veterinarian' },
  { label: 'Other', value: 'Other' },
];

export const SF_MAX_CHAR_LIMITS = {
  CHAR_FIELD: 255,
  TEXT_AREA: 1000,
  EMAIL: 80,
} as const;

export const UNITED_STATES = [
  { label: 'Alabama', value: 'Alabama', abbreviation: 'AL' },
  { label: 'Alaska', value: 'Alaska', abbreviation: 'AK' },
  { label: 'Arizona', value: 'Arizona', abbreviation: 'AZ' },
  { label: 'Arkansas', value: 'Arkansas', abbreviation: 'AR' },
  { label: 'California', value: 'California', abbreviation: 'CA' },
  { label: 'Colorado', value: 'Colorado', abbreviation: 'CO' },
  { label: 'Connecticut', value: 'Connecticut', abbreviation: 'CT' },
  { label: 'Delaware', value: 'Delaware', abbreviation: 'DE' },
  {
    label: 'District of Columbia (Washington, D.C.)',
    value: 'Washington, D.C.',
    abbreviation: 'DC',
  },
  { label: 'Florida', value: 'Florida', abbreviation: 'FL' },
  { label: 'Georgia', value: 'Georgia', abbreviation: 'GA' },
  { label: 'Hawaii', value: 'Hawaii', abbreviation: 'HI' },
  { label: 'Idaho', value: 'Idaho', abbreviation: 'ID' },
  { label: 'Illinois', value: 'Illinois', abbreviation: 'IL' },
  { label: 'Indiana', value: 'Indiana', abbreviation: 'IN' },
  { label: 'Iowa', value: 'Iowa', abbreviation: 'IA' },
  { label: 'Kansas', value: 'Kansas', abbreviation: 'KS' },
  { label: 'Kentucky', value: 'Kentucky', abbreviation: 'KY' },
  { label: 'Louisiana', value: 'Louisiana', abbreviation: 'LA' },
  { label: 'Maine', value: 'Maine', abbreviation: 'ME' },
  { label: 'Maryland', value: 'Maryland', abbreviation: 'MD' },
  { label: 'Massachusetts', value: 'Massachusetts', abbreviation: 'MA' },
  { label: 'Michigan', value: 'Michigan', abbreviation: 'MI' },
  { label: 'Minnesota', value: 'Minnesota', abbreviation: 'MN' },
  { label: 'Mississippi', value: 'Mississippi', abbreviation: 'MS' },
  { label: 'Missouri', value: 'Missouri', abbreviation: 'MO' },
  { label: 'Montana', value: 'Montana', abbreviation: 'MT' },
  { label: 'Nebraska', value: 'Nebraska', abbreviation: 'NE' },
  { label: 'Nevada', value: 'Nevada', abbreviation: 'NV' },
  { label: 'New Hampshire', value: 'New Hampshire', abbreviation: 'NH' },
  { label: 'New Jersey', value: 'New Jersey', abbreviation: 'NJ' },
  { label: 'New Mexico', value: 'New Mexico', abbreviation: 'NM' },
  { label: 'New York', value: 'New York', abbreviation: 'NY' },
  { label: 'North Carolina', value: 'North Carolina', abbreviation: 'NC' },
  { label: 'North Dakota', value: 'North Dakota', abbreviation: 'ND' },
  { label: 'Ohio', value: 'Ohio', abbreviation: 'OH' },
  { label: 'Oklahoma', value: 'Oklahoma', abbreviation: 'OK' },
  { label: 'Oregon', value: 'Oregon', abbreviation: 'OR' },
  { label: 'Pennsylvania', value: 'Pennsylvania', abbreviation: 'PA' },
  { label: 'Rhode Island', value: 'Rhode Island', abbreviation: 'RI' },
  { label: 'South Carolina', value: 'South Carolina', abbreviation: 'SC' },
  { label: 'South Dakota', value: 'South Dakota', abbreviation: 'SD' },
  { label: 'Tennessee', value: 'Tennessee', abbreviation: 'TN' },
  { label: 'Texas', value: 'Texas', abbreviation: 'TX' },
  { label: 'Utah', value: 'Utah', abbreviation: 'UT' },
  { label: 'Vermont', value: 'Vermont', abbreviation: 'VT' },
  { label: 'Virginia', value: 'Virginia', abbreviation: 'VA' },
  { label: 'Washington', value: 'Washington', abbreviation: 'WA' },
  { label: 'West Virginia', value: 'West Virginia', abbreviation: 'WV' },
  { label: 'Wisconsin', value: 'Wisconsin', abbreviation: 'WI' },
  { label: 'Wyoming', value: 'Wyoming', abbreviation: 'WY' },
];

export const maxFileSizeInBytes = 50 * 1024 * 1024; // 50MB

export const genericRequiredFieldError = 'This field is required';

export const COUNTRIES = [
  { name: 'Afghanistan', code: 'AF' },
  { name: 'Åland Islands', code: 'AX' },
  { name: 'Albania', code: 'AL' },
  { name: 'Algeria', code: 'DZ' },
  { name: 'American Samoa', code: 'AS' },
  { name: 'Andorra', code: 'AD' },
  { name: 'Angola', code: 'AO' },
  { name: 'Anguilla', code: 'AI' },
  { name: 'Antarctica', code: 'AQ' },
  { name: 'Antigua and Barbuda', code: 'AG' },
  { name: 'Argentina', code: 'AR' },
  { name: 'Armenia', code: 'AM' },
  { name: 'Aruba', code: 'AW' },
  { name: 'Australia', code: 'AU' },
  { name: 'Austria', code: 'AT' },
  { name: 'Azerbaijan', code: 'AZ' },
  { name: 'Bahamas', code: 'BS' },
  { name: 'Bahrain', code: 'BH' },
  { name: 'Bangladesh', code: 'BD' },
  { name: 'Barbados', code: 'BB' },
  { name: 'Belarus', code: 'BY' },
  { name: 'Belgium', code: 'BE' },
  { name: 'Belize', code: 'BZ' },
  { name: 'Benin', code: 'BJ' },
  { name: 'Bermuda', code: 'BM' },
  { name: 'Bhutan', code: 'BT' },
  { name: 'Bolivia', code: 'BO' },
  { name: 'Bosnia and Herzegovina', code: 'BA' },
  { name: 'Botswana', code: 'BW' },
  { name: 'Bouvet Island', code: 'BV' },
  { name: 'Brazil', code: 'BR' },
  { name: 'British Indian Ocean Territory', code: 'IO' },
  { name: 'Brunei Darussalam', code: 'BN' },
  { name: 'Bulgaria', code: 'BG' },
  { name: 'Burkina Faso', code: 'BF' },
  { name: 'Burundi', code: 'BI' },
  { name: 'Cambodia', code: 'KH' },
  { name: 'Cameroon', code: 'CM' },
  { name: 'Canada', code: 'CA' },
  { name: 'Cape Verde', code: 'CV' },
  { name: 'Cayman Islands', code: 'KY' },
  { name: 'Central African Republic', code: 'CF' },
  { name: 'Chad', code: 'TD' },
  { name: 'Chile', code: 'CL' },
  { name: 'China', code: 'CN' },
  { name: 'Christmas Island', code: 'CX' },
  { name: 'Cocos (Keeling) Islands', code: 'CC' },
  { name: 'Colombia', code: 'CO' },
  { name: 'Comoros', code: 'KM' },
  { name: 'Congo', code: 'CG' },
  { name: 'Congo, The Democratic Republic of the', code: 'CD' },
  { name: 'Cook Islands', code: 'CK' },
  { name: 'Costa Rica', code: 'CR' },
  { name: "Cote D'Ivoire", code: 'CI' },
  { name: 'Croatia', code: 'HR' },
  { name: 'Cuba', code: 'CU' },
  { name: 'Cyprus', code: 'CY' },
  { name: 'Czech Republic', code: 'CZ' },
  { name: 'Denmark', code: 'DK' },
  { name: 'Djibouti', code: 'DJ' },
  { name: 'Dominica', code: 'DM' },
  { name: 'Dominican Republic', code: 'DO' },
  { name: 'Ecuador', code: 'EC' },
  { name: 'Egypt', code: 'EG' },
  { name: 'El Salvador', code: 'SV' },
  { name: 'Equatorial Guinea', code: 'GQ' },
  { name: 'Eritrea', code: 'ER' },
  { name: 'Estonia', code: 'EE' },
  { name: 'Ethiopia', code: 'ET' },
  { name: 'Falkland Islands (Malvinas)', code: 'FK' },
  { name: 'Faroe Islands', code: 'FO' },
  { name: 'Fiji', code: 'FJ' },
  { name: 'Finland', code: 'FI' },
  { name: 'France', code: 'FR' },
  { name: 'French Guiana', code: 'GF' },
  { name: 'French Polynesia', code: 'PF' },
  { name: 'French Southern Territories', code: 'TF' },
  { name: 'Gabon', code: 'GA' },
  { name: 'Gambia', code: 'GM' },
  { name: 'Georgia', code: 'GE' },
  { name: 'Germany', code: 'DE' },
  { name: 'Ghana', code: 'GH' },
  { name: 'Gibraltar', code: 'GI' },
  { name: 'Greece', code: 'GR' },
  { name: 'Greenland', code: 'GL' },
  { name: 'Grenada', code: 'GD' },
  { name: 'Guadeloupe', code: 'GP' },
  { name: 'Guam', code: 'GU' },
  { name: 'Guatemala', code: 'GT' },
  { name: 'Guernsey', code: 'GG' },
  { name: 'Guinea', code: 'GN' },
  { name: 'Guinea-Bissau', code: 'GW' },
  { name: 'Guyana', code: 'GY' },
  { name: 'Haiti', code: 'HT' },
  { name: 'Heard Island and Mcdonald Islands', code: 'HM' },
  { name: 'Holy See (Vatican City State)', code: 'VA' },
  { name: 'Honduras', code: 'HN' },
  { name: 'Hong Kong', code: 'HK' },
  { name: 'Hungary', code: 'HU' },
  { name: 'Iceland', code: 'IS' },
  { name: 'India', code: 'IN' },
  { name: 'Indonesia', code: 'ID' },
  { name: 'Iran, Islamic Republic Of', code: 'IR' },
  { name: 'Iraq', code: 'IQ' },
  { name: 'Ireland', code: 'IE' },
  { name: 'Isle of Man', code: 'IM' },
  { name: 'Israel', code: 'IL' },
  { name: 'Italy', code: 'IT' },
  { name: 'Jamaica', code: 'JM' },
  { name: 'Japan', code: 'JP' },
  { name: 'Jersey', code: 'JE' },
  { name: 'Jordan', code: 'JO' },
  { name: 'Kazakhstan', code: 'KZ' },
  { name: 'Kenya', code: 'KE' },
  { name: 'Kiribati', code: 'KI' },
  { name: "Korea, Democratic People'S Republic of", code: 'KP' },
  { name: 'Korea, Republic of', code: 'KR' },
  { name: 'Kuwait', code: 'KW' },
  { name: 'Kyrgyzstan', code: 'KG' },
  { name: "Lao People'S Democratic Republic", code: 'LA' },
  { name: 'Latvia', code: 'LV' },
  { name: 'Lebanon', code: 'LB' },
  { name: 'Lesotho', code: 'LS' },
  { name: 'Liberia', code: 'LR' },
  { name: 'Libyan Arab Jamahiriya', code: 'LY' },
  { name: 'Liechtenstein', code: 'LI' },
  { name: 'Lithuania', code: 'LT' },
  { name: 'Luxembourg', code: 'LU' },
  { name: 'Macao', code: 'MO' },
  { name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK' },
  { name: 'Madagascar', code: 'MG' },
  { name: 'Malawi', code: 'MW' },
  { name: 'Malaysia', code: 'MY' },
  { name: 'Maldives', code: 'MV' },
  { name: 'Mali', code: 'ML' },
  { name: 'Malta', code: 'MT' },
  { name: 'Marshall Islands', code: 'MH' },
  { name: 'Martinique', code: 'MQ' },
  { name: 'Mauritania', code: 'MR' },
  { name: 'Mauritius', code: 'MU' },
  { name: 'Mayotte', code: 'YT' },
  { name: 'Mexico', code: 'MX' },
  { name: 'Micronesia, Federated States of', code: 'FM' },
  { name: 'Moldova, Republic of', code: 'MD' },
  { name: 'Monaco', code: 'MC' },
  { name: 'Mongolia', code: 'MN' },
  { name: 'Montserrat', code: 'MS' },
  { name: 'Morocco', code: 'MA' },
  { name: 'Mozambique', code: 'MZ' },
  { name: 'Myanmar', code: 'MM' },
  { name: 'Namibia', code: 'NA' },
  { name: 'Nauru', code: 'NR' },
  { name: 'Nepal', code: 'NP' },
  { name: 'Netherlands', code: 'NL' },
  { name: 'Netherlands Antilles', code: 'AN' },
  { name: 'New Caledonia', code: 'NC' },
  { name: 'New Zealand', code: 'NZ' },
  { name: 'Nicaragua', code: 'NI' },
  { name: 'Niger', code: 'NE' },
  { name: 'Nigeria', code: 'NG' },
  { name: 'Niue', code: 'NU' },
  { name: 'Norfolk Island', code: 'NF' },
  { name: 'Northern Mariana Islands', code: 'MP' },
  { name: 'Norway', code: 'NO' },
  { name: 'Oman', code: 'OM' },
  { name: 'Pakistan', code: 'PK' },
  { name: 'Palau', code: 'PW' },
  { name: 'Palestinian Territory, Occupied', code: 'PS' },
  { name: 'Panama', code: 'PA' },
  { name: 'Papua New Guinea', code: 'PG' },
  { name: 'Paraguay', code: 'PY' },
  { name: 'Peru', code: 'PE' },
  { name: 'Philippines', code: 'PH' },
  { name: 'Pitcairn', code: 'PN' },
  { name: 'Poland', code: 'PL' },
  { name: 'Portugal', code: 'PT' },
  { name: 'Puerto Rico', code: 'PR' },
  { name: 'Qatar', code: 'QA' },
  { name: 'Reunion', code: 'RE' },
  { name: 'Romania', code: 'RO' },
  { name: 'Russian Federation', code: 'RU' },
  { name: 'RWANDA', code: 'RW' },
  { name: 'Saint Helena', code: 'SH' },
  { name: 'Saint Kitts and Nevis', code: 'KN' },
  { name: 'Saint Lucia', code: 'LC' },
  { name: 'Saint Pierre and Miquelon', code: 'PM' },
  { name: 'Saint Vincent and the Grenadines', code: 'VC' },
  { name: 'Samoa', code: 'WS' },
  { name: 'San Marino', code: 'SM' },
  { name: 'Sao Tome and Principe', code: 'ST' },
  { name: 'Saudi Arabia', code: 'SA' },
  { name: 'Senegal', code: 'SN' },
  { name: 'Serbia and Montenegro', code: 'CS' },
  { name: 'Seychelles', code: 'SC' },
  { name: 'Sierra Leone', code: 'SL' },
  { name: 'Singapore', code: 'SG' },
  { name: 'Slovakia', code: 'SK' },
  { name: 'Slovenia', code: 'SI' },
  { name: 'Solomon Islands', code: 'SB' },
  { name: 'Somalia', code: 'SO' },
  { name: 'South Africa', code: 'ZA' },
  { name: 'South Georgia and the South Sandwich Islands', code: 'GS' },
  { name: 'Spain', code: 'ES' },
  { name: 'Sri Lanka', code: 'LK' },
  { name: 'Sudan', code: 'SD' },
  { name: 'Suriname', code: 'SR' },
  { name: 'Svalbard and Jan Mayen', code: 'SJ' },
  { name: 'Swaziland', code: 'SZ' },
  { name: 'Sweden', code: 'SE' },
  { name: 'Switzerland', code: 'CH' },
  { name: 'Syrian Arab Republic', code: 'SY' },
  { name: 'Taiwan, Province of China', code: 'TW' },
  { name: 'Tajikistan', code: 'TJ' },
  { name: 'Tanzania, United Republic of', code: 'TZ' },
  { name: 'Thailand', code: 'TH' },
  { name: 'Timor-Leste', code: 'TL' },
  { name: 'Togo', code: 'TG' },
  { name: 'Tokelau', code: 'TK' },
  { name: 'Tonga', code: 'TO' },
  { name: 'Trinidad and Tobago', code: 'TT' },
  { name: 'Tunisia', code: 'TN' },
  { name: 'Turkey', code: 'TR' },
  { name: 'Turkmenistan', code: 'TM' },
  { name: 'Turks and Caicos Islands', code: 'TC' },
  { name: 'Tuvalu', code: 'TV' },
  { name: 'Uganda', code: 'UG' },
  { name: 'Ukraine', code: 'UA' },
  { name: 'United Arab Emirates', code: 'AE' },
  { name: 'United Kingdom', code: 'GB' },
  { name: 'United States', code: 'US' },
  { name: 'United States Minor Outlying Islands', code: 'UM' },
  { name: 'Uruguay', code: 'UY' },
  { name: 'Uzbekistan', code: 'UZ' },
  { name: 'Vanuatu', code: 'VU' },
  { name: 'Venezuela', code: 'VE' },
  { name: 'Viet Nam', code: 'VN' },
  { name: 'Virgin Islands, British', code: 'VG' },
  { name: 'Virgin Islands, U.S.', code: 'VI' },
  { name: 'Wallis and Futuna', code: 'WF' },
  { name: 'Western Sahara', code: 'EH' },
  { name: 'Yemen', code: 'YE' },
  { name: 'Zambia', code: 'ZM' },
  { name: 'Zimbabwe', code: 'ZW' },
];

export const CO_UTILITIES = [
  { label: 'Xcel Energy', value: 'Xcel Energy', abbreviation: null },
  { label: 'Other', value: 'Other', abbreviation: null },
];

export const IL_UTILITIES = [
  { label: 'Ameren Illinois', value: 'Ameren Illinois', abbreviation: null },
  {
    label: 'Commonwealth Edison',
    value: 'Commonwealth Edison',
    abbreviation: null,
  },
  {
    label: 'MidAmerican Energy',
    value: 'MidAmerican Energy',
    abbreviation: null,
  },
  { label: 'Mt. Carmel', value: 'Mt. Carmel', abbreviation: null },
  { label: 'Other (MISO)', value: 'Other (MISO)', abbreviation: null },
  { label: 'Other (PJM)', value: 'Other (PJM)  ', abbreviation: null },
];

export const MA_TERRITORIES = [
  { label: 'Eversource East', value: 'Eversource East', abbreviation: null },
  { label: 'Eversource West', value: 'Eversource West', abbreviation: null },
  {
    label: 'National Grid (Massachusetts Electric)',
    value: 'National Grid (Massachusetts Electric)',
    abbreviation: null,
  },
  {
    label: 'National Grid (Nantucket)',
    value: 'National Grid (Nantucket)',
    abbreviation: null,
  },
  { label: 'Unitil', value: 'Unitil', abbreviation: null },
  { label: 'Other', value: 'Other', abbreviation: null },
];

export const DSCR_AVG_LIMIT_1 = 1;
export const DSCR_AVG_LIMIT_2 = 0.7;
export const DSCR_MIN_LIMIT_1 = 0.8;
export const DSCR_MIN_LIMIT_2 = 0.5;

export const EXPIRATION_WARNING_CHANGE_DATE = new Date('2023-10-28T00:00:00Z');

export const APPLICATION_EXTENSION_LENGTH_IN_DAYS = 10;

export const FIN_OPS_EMAIL = 'finops@sunstonecredit.com';
export const UNDERWRITING_EMAIL = 'underwriting@sunstonecredit.com';

export const MILLISECONDS_IN_A_DAY = 86400000;

export const PG_PERCENT_OWNERSHIP_FLOOR = 51;

export const AXIOS_TIMEOUT_MS = 180000;

export const TYPE_OF_ADVANCE_FUNDING = {
  GREENTECH_DIRECT_PAY: 'Greentech Direct Pay',
} as const;

export const INSTALLER_PROGRAM_STATUSES = {
  APPLICATION_SUBMITTED: 'Application Submitted',
  APPLICATION_IN_PROGRESS: 'Application In-Progress',
  APPROVED: 'Approved',
  DECLINED: 'Declined',
  NOT_ELIGIBLE: 'Not Eligible',
};

export const INSTALLER_PROGRAM_TYPES = {
  MILESTONE_PROGRAM: 'Milestone Program',
  GREENTECH_DIRECT_PAY_PROGRAM: 'Greentech Direct Pay Program',
} as const;

export const FP_ADVANCE_FUNDING_TYPES = {
  MILESTONE_PROGRAM: 'Milestone Program',
  GREENTECH_DIRECT_PAY_PROGRAM: 'Greentech Direct Pay Program',
  EQUIPMENT_ADVANCE: 'Equipment Advance',
  PROJECT_ADVANCE: 'Project Advance',
} as const;

export const MILESTONE_STATUSES = {
  NOT_STARTED: 'Not Started',
  IN_PROGRESS: 'In Progress',
  COMPLETED: 'Completed',
  PAID: 'Paid',
  WAIVED: 'Waived',
} as const;

export const ADVANCE_FUNDING_MILESTONES = ['EO', 'MC', 'RC'];

export const FUNDING_LIMIT_WARNING_PERCENTAGE = 25;

export enum COLORS {
  GRAY = '#888888',
  LIGHT_GRAY = '#DDDDDD',
  MILESTONE_GREEN = '#2E9C7F',
  MILESTONE_GREEN_BACKGROUND = '#E3EEEA',
  WARNING_ORANGE = '#FB9906',
  WARNING_RED = '#E95857',
}

export enum SUNSTONE_DOCUMENT_APPROVAL_STATUSES {
  APPROVED = 'Approved',
  AWAITING_UPLOAD = 'Awaiting Upload',
  ISSUES_IDENTIFIED = 'Issues Identified',
  PENDING_CALL = 'Pending Call',
  SENT_VIA_DOCUSIGN = 'Sent via Docusign',
  SIGNATURE_NEEDED = 'Signature Needed',
  WAITING_REVIEW = 'Waiting Review',
}

export enum LIMITS {
  MIN_YEAR_ALLOWED = 1637,
  MAX_YEARS_ESTABLISHED = 400,
  PG_LOAN_AMOUNT_CEILING = 250000,
  EXPRESS_PLUS_LOAN_AMOUNT_FLOOR = 250000,
  EXPRESS_PLUS_LOAN_AMOUNT_CEILING = 500000,
  EXPRESS_PLUS_REVENUE_CEILING = 10000000,
  EXPRESS_REVENUE_CEILING = 5000000,
  YEARS_AGO_ESTABLISHED_FLOOR = 5,
}

export const COMPANY_TYPES_OBJECT_ARRAY = [
  { label: 'Operating Company', value: 'Operating Company' },
  { label: 'Property Company', value: 'Property Company' },
  { label: 'Agriculture', value: 'Agriculture' },
  { label: 'Non-Profit', value: 'Non-Profit' },
  { label: 'HOA', value: 'HOA' },
];

export enum COMPANY_TYPES {
  OPERATING_COMPANY = 'Operating Company',
  PROPERTY_COMPANY = 'Property Company',
  AGRICULTURE = 'Agriculture',
  NON_PROFIT = 'Non-Profit',
  HOA = 'HOA',
}

export const PG_COMPANY_TYPES = [
  COMPANY_TYPES.OPERATING_COMPANY,
  COMPANY_TYPES.PROPERTY_COMPANY,
  COMPANY_TYPES.AGRICULTURE,
];

export const SPECIAL_PAYMENT_REQUEST_OPTIONS = [
  { label: '30%', value: 30 },
  { label: '40%', value: 40 },
  { label: '50%', value: 50 },
];

export const CALCULATOR_TOOLTIPS = (companyName?: string) => ({
  MONTHLY_PAYMENT:
    'The estimated monthly payment amount is determined by the Loan Amount, Loan Type, Loan Term and Interest Rate.',
  NET_FUNDING: `The amount ${companyName} will receive from Sunstone after dealer fees. This number assumes 90%/10% payment terms (i.e., all payments are made after Substantial Completion) and no additional discounts or fees apply.`,
  TOTAL_LOAN_AMOUNT:
    'The amount that will appear on the customer’s loan documents.',
  SPECIAL_PAYMENT_REQUEST:
    'Larger Special Payments result in lower monthly payments. The Special Payment is due by Month 18. Typically the 40% and 50% Special Payments are associated with ITC adders (eg Domestic content) or a REAP grant.',
});

export const PRODUCT_FAMILY_CONFIG = {
  [ProductFamily.STANDARD]: {
    label: 'Standard Loan',
    description:
      'Fixed monthly payments. Tend to have higher interest rates and monthly payments.',
    icon: StandardIcon,
    bulletPoints: [
      'Same monthly payment for the life of the loan',
      'No additional payments outside of the monthly payment',
      'Generally a larger monthly payment in comparison to the Special Payment Product',
    ],
  },
  [ProductFamily.SPECIAL_PAYMENT]: {
    label: 'Special Payment',
    description:
      'Fixed monthly payments and a special payment due in month 18. Tend to have lower interest rates and monthly payments.',
    icon: CalendarIcon,
    bulletPoints: [
      'Mandatory one-time special payment by month 18',
      'Generally smaller monthly payments due to the special payment',
      'Often used when there is certainty of the customer to receive incentives like ITC, REAP, etc',
    ],
  },
  [ProductFamily.PREMIUM]: {
    label: 'Premium',
    description: '',
    icon: undefined,
    bulletPoints: [],
  },
};

export enum Z_INDEXES {
  LOAN_PRODUCT_SUMMARY_BAR = 1200,
  ANNOUNCEMENTS_TAB_DESKTOP = 1101,
  ANNOUNCEMENTS_ICON_DESKTOP = 1300,
  ANNOUNCEMENTS_TAB_MOBILE = 1999,
  ANNOUNCEMENTS_ICON_MOBILE = 700,
  MODAL = 2999,
  ACCOUNT_POPOVER = 9999,
  NAVBAR = 1100,
  APPLICATION_ASIDE = 1099,
  MOBILE_NAVBAR = 2000,
  SPLASH_SCREEN = 2000,
  REVIEW_BUTTON = 1,
  TOOLTIP_POPOVER = 999,
}

export const INTERVAL_DATES = () => {
  const currentYear = new Date().getFullYear(); // Some Edge Case with local TZ
  return {
    JanApr: isCurrentDateBetween(
      `${currentYear}-01-01 00:00:00`,
      `${currentYear}-04-30 23:59:59`
    ),
    MayOct: isCurrentDateBetween(
      `${currentYear}-05-01 00:00:00`,
      `${currentYear}-10-31 23:59:59`
    ),
    NovDec: isCurrentDateBetween(
      `${currentYear}-11-01 00:00:00`,
      `${currentYear}-12-31 23:59:59`
    ),
    MidMaytoMidAug: isCurrentDateBetween(
      `${currentYear}-05-15 00:00:00`,
      `${currentYear}-08-14 23:59:59`
    ),
    MidAugtoMidNov: isCurrentDateBetween(
      `${currentYear}-08-15 00:00:00`,
      `${currentYear}-11-14 23:59:59`
    ),
    MidNovDec: isCurrentDateBetween(
      `${currentYear}-11-15 00:00:00`,
      `${currentYear}-12-31 23:59:59`
    ),
  };
};

export enum BORROWER_SUCCESS_CONTACT_INFO {
  NAME = 'Borrower Success',
  EMAIL = 'borrowersuccess@sunstonecredit.com',
  PHONE = '(800)-383-4910',
}

export enum PARTNER_SUCCESS_CONTACT_INFO {
  NAME = 'Partner Success Team',
  EMAIL = 'partnersuccess@sunstonecredit.com',
}

export const OUTSTANDING_LOAN_TYPES = [
  { label: 'Term Loan', value: 'Term Loan' },
  { label: 'Line of Credit', value: 'Line of Credit' },
  { label: 'SBA Loan', value: 'SBA Loan' },
  {
    label: 'Commercial Real Estate Loan',
    value: 'Commercial Real Estate Loan',
  },
  { label: 'Equipment Financing', value: 'Equipment Financing' },
  { label: 'Auto Loan', value: 'Auto Loan' },
  { label: 'Construction Loan', value: 'Construction Loan' },
  { label: 'Mortgage', value: 'Mortgage' },
  { label: 'Shareholder Loan', value: 'Shareholder Loan' },
  { label: 'COVID-related Loan', value: 'COVID-related Loan' },
  { label: 'Other', value: 'Other' },
];

export const DOCUMENT_TYPE = {
  LOAN_PROPOSAL: 'Solar Loan Proposal',
  UTILITY_BILLS: 'Utility Bills',
  INCENTIVE_DOCUMENTS: 'Incentive Documents',
};

export enum REQUIRED_DOCUMENT_SUB_TYPES {
  PG = 'PG',
  CG = 'CG',
  BORROWER = 'BORROWER',
}

export enum APPLICATION_BORROWER_BUSINESS_STRUCTURE {
  SOLE_PROPRIETORSHIP = 'SOLE PROPRIETORSHIP',
  NON_PROFIT = 'NON-PROFIT',
  HOA = 'HOA',
  SCORP = 'S-CORP',
  CCORP = 'C-CORP',
  LLC = 'LLC',
  LLP = 'LLP',
  GENERAL_PARTNERSHIP = 'GENERAL PARTNERSHIP',
}

export enum MULTIPLE_USER_POWER_AGREEMENT {
  PPA_LEASE = 'PPA/Lease Agreement',
  COMMUNITY_SOLAR = 'Community Solar Agreement',
}

export const REQUIRED_DOCUMENT_REQUIRED_FOR_STEP = {
  BORROWER_APP_SUBMISSION: 'BORROWER_APP_SUBMISSION',
  UNDERWRITING_APPROVAL: 'UNDERWRITING_APPROVAL',
  BORROWER_SIGNING: 'BORROWER_SIGNING',
  PROJECT_START: 'PROJECT_START',
  NOTICE_TO_PROCEED: 'NOTICE_TO_PROCEED',
  EQUIPMENT_ORDER: 'EQUIPMENT_ORDER',
  RACKING_COMPLETION: 'RACKING_COMPLETION',
  MECHANICAL_COMPLETION: 'MECHANICAL_COMPLETION',
  SUBSTANTIAL_COMPLETION: 'SUBSTANTIAL_COMPLETION',
  FINAL_COMPLETION: 'FINAL_COMPLETION',
  FULLY_FUNDED: 'FULLY_FUNDED',
} as const;

// Mapping of required for step values from SF Sunstone Document to the values used in Postgres required_documents
export const SF_REQUIRED_FOR_STEP_MAPPING = {
  'Borrower App Submission':
    REQUIRED_DOCUMENT_REQUIRED_FOR_STEP.BORROWER_APP_SUBMISSION,
  'Underwriting Approval':
    REQUIRED_DOCUMENT_REQUIRED_FOR_STEP.UNDERWRITING_APPROVAL,
  'Borrower Signing': REQUIRED_DOCUMENT_REQUIRED_FOR_STEP.BORROWER_SIGNING,
  'Project Start': REQUIRED_DOCUMENT_REQUIRED_FOR_STEP.PROJECT_START,
  'Notice to Proceed': REQUIRED_DOCUMENT_REQUIRED_FOR_STEP.NOTICE_TO_PROCEED,
  'Equipment Order': REQUIRED_DOCUMENT_REQUIRED_FOR_STEP.EQUIPMENT_ORDER,
  'Racking Completion': REQUIRED_DOCUMENT_REQUIRED_FOR_STEP.RACKING_COMPLETION,
  'Mechanical Completion':
    REQUIRED_DOCUMENT_REQUIRED_FOR_STEP.MECHANICAL_COMPLETION,
  'Substantial Completion':
    REQUIRED_DOCUMENT_REQUIRED_FOR_STEP.SUBSTANTIAL_COMPLETION,
  'Final Completion': REQUIRED_DOCUMENT_REQUIRED_FOR_STEP.FINAL_COMPLETION,
  'Fully Funded': REQUIRED_DOCUMENT_REQUIRED_FOR_STEP.FULLY_FUNDED,
} as const;
