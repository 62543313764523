import { LottieOptions as LazyLottieOptions } from 'lottie-react';
import React, { ReactNode, Suspense } from 'react';

const LazyLottie = React.lazy(() => import('lottie-react'));

type LottieOptions = {
  defaultImage?: ReactNode;
} & Omit<LazyLottieOptions, 'ref'>;

export default function Lottie({ defaultImage, ...rest }: LottieOptions) {
  return (
    <Suspense fallback={<>{defaultImage ?? null}</>}>
      <LazyLottie {...rest} />
    </Suspense>
  );
}
